import Module from "./Module";

export default class ModulePopupContent extends Module {

    constructor(el) {
        super(el);
    }

    init() {
        var that = this;

        setTimeout(function () {
            that.initPopup();
        }, 1750);
    }

    initPopup() {
        this.modalOverlay = document.querySelector(".popupcontent");
        this.modalBox = document.querySelector(".popup__container");

        this.siteIndex = $('#site').find('a, area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]');
        this.focusableEls = $(this.modalBox).find('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]');

        var that = this;
        var mouseDown = false;

        this.siteIndex = $.makeArray(this.siteIndex);

        $.each(this.siteIndex, function (i, el) {
            $(el).attr('tabindex', -1);
        });

        this.modalOverlay.removeAttribute("aria-hidden");
        this.modalBox.removeAttribute('aria-hidden');

        this.focusableEls = $.makeArray(this.focusableEls);

        $.each(this.focusableEls, function (i, el) {
            $(el).attr('tabindex', i + 1);
        });

        this.modalOverlay.addEventListener("click", (e) => {
            if(e.target == this.modalOverlay || e.target == this.modalBox.querySelector(".popup__close-btn")){
                that.setCookies();
                that.closeDialog();
            }
        });
        
        this.modalBox.querySelector(".popup__close-btn").addEventListener("mousedown", (e) => {
            mouseDown = true;
        });

        this.modalBox.querySelector(".popup__close-btn").addEventListener("mouseup", (e) => {
            mouseDown = false;
        });

        this.modalBox.querySelector(".popup__close-btn").addEventListener("focus", (e) => {
            if (mouseDown) {
                e.currentTarget.blur();
            }
        });

        $(document).keyup(function (e) {
            if (e.keyCode === 27) {
                that.closeDialog();
            }

            if (!e.shiftKey && e.keyCode === 9) {
                if (document.activeElement === this.lastFocusableEl || document.activeElement === this.lastFocusableLink) {
                    e.preventDefault();
                    this.firstFocusableEl.focus();
                }
            }
    
            if (e.shiftKey && e.keyCode === 9) {
                if (document.activeElement === this.firstFocusableEl) {
                    e.preventDefault();
                    this.lastFocusableLink.focus();
                }
            }
        });

        document.querySelector("html").style.overflow = "hidden";
        document.querySelector("body").style.overflow = "hidden";
       
        this.modalOverlay.classList.add("fade-in");
        
    }

    closeDialog() {
        this.modalOverlay.classList.add("fade-out");

        this.modalBox.setAttribute("aria-hidden", true);
        this.modalOverlay.setAttribute("aria-hidden", true);

        $.each(this.siteIndex, function (i, el) {
            $(el).removeAttr('tabindex');
        });

        $.each(this.focusableEls, function (i, el) {
            $(el).attr('tabindex', -1);
        });

        document.querySelector("html").style.overflow = "visible";
        document.querySelector("body").style.overflow = "visible";   

        setTimeout(() => {
            this.modalOverlay.parentElement.removeChild(this.modalOverlay);
        }, 1750);

    }

    setCookies() {
        var sesCookieName = $(this.dom.$el).attr('data-content-sid');
        if (sesCookieName) {
            document.cookie = sesCookieName + '=true; path=/';
        }
        var foCookieName = $(this.dom.$el).attr('data-content-fid');
        if (foCookieName) {
            var cookieDate = new Date();
            cookieDate.setMonth(cookieDate.getMonth() + 1);
            document.cookie = foCookieName + '=true; expires=' + cookieDate.toGMTString() + '; path=/';
        }
    }

}