export default class Module {
    constructor(el) {
        this.dom = {
            el: el,
            $el: el,
            body: document.querySelector("body"),
            html: document.querySelector("html"),
            site: document.querySelector("#site"),
            w: window
        };
    }
}